import {
  CellId,
  CellType,
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  HexVersionId,
  SQLCellBlockConfig,
  fractionalIndexMidpoint95,
  initialMidpoint95,
} from "@hex/common";

import { RootState } from "../../store.js";
import { CellMP, hexVersionMPSelectors } from "../hexVersionMPSlice.js";
import { cellSelectionSelectors } from "../logicViewSlice.js";

import {
  CellLocation,
  ChildCellLocation,
  GlobalCellLocation,
  RelativeCellLocation,
  ResolvedCellLocation,
} from "./CellLocation.js";

export interface DereferenceChildCellOptions {
  /**
   * Trying to insert/move a cell into an existing component import cell
   * is usually a mistake, so by default we "dereference" the children
   * to the parent cell to prevent this common mistake
   *
   * @default true
   */
  dereferenceComponentImportCellChildren?: boolean;
  /**
   * Trying to insert/move a cell into an existing SQL block cell
   * is usually a mistake, so by default we "dereference" the children
   * to the parent cell to prevent this common mistake
   *
   * @default true
   */
  dereferenceSqlBlockCellChildren?: boolean;
}

interface ResolveCellCommonArgs extends DereferenceChildCellOptions {
  hexVersionId: HexVersionId;
  state: RootState;
  /**
   * Trying to insert/move a cell into an existing component import cell
   * is usually a mistake, so by default we "dereference" the children
   * to the parent cell to prevent this common mistake
   *
   * @default true
   */
  dereferenceComponentImportCellChildren?: boolean;
  /**
   * Trying to insert/move a cell into an existing SQL block cell
   * is usually a mistake, so by default we "dereference" the children
   * to the parent cell to prevent this common mistake
   *
   * @default true
   */
  dereferenceSqlBlockCellChildren?: boolean;
}

/**
 * Converts a declarative {@link CellLocation} to
 * a concrete {@link ResolvedCellLocation} so that an
 * insert / move can actually occur or render something
 * else at the potential insert location.
 */
export function resolveCellLocation({
  location,
  ...args
}: ResolveCellCommonArgs & {
  location: CellLocation;
}): ResolvedCellLocation {
  if (location.type === "global") {
    return resolveGlobalCellLocation({ location, ...args });
  } else if (location.type === "child") {
    return resolveChildCellLocation({ location, ...args });
  } else {
    return resolveRelativeCellLocation({ location, ...args });
  }
}

function resolveRelativeCellLocation({
  dereferenceComponentImportCellChildren = true,
  dereferenceSqlBlockCellChildren = true,
  hexVersionId,
  location,
  state,
}: ResolveCellCommonArgs & {
  location: RelativeCellLocation;
}): ResolvedCellLocation {
  const targetCell = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectById(state, location.targetCellId);

  if (targetCell == null) {
    throw new Error(`Unable to find target cell ${location.targetCellId}`);
  }

  const parentCellId = targetCell.parentCellId ?? null;

  const parentCell =
    parentCellId != null
      ? hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, parentCellId)
      : undefined;

  if (parentCell != null) {
    const componentImportCell = dereferenceComponentImportCellChildren
      ? recursivelyFindComponentImportCell({
          cell: parentCell,
          state,
          hexVersionId,
        })
      : null;

    if (componentImportCell != null) {
      return resolveCellLocation({
        state,
        hexVersionId,
        location: {
          type: "relative",
          targetCellId: componentImportCell.id,
          position: location.position,
        },
        dereferenceComponentImportCellChildren,
        dereferenceSqlBlockCellChildren,
      });
    }

    const isSqlBlockCell =
      dereferenceSqlBlockCellChildren &&
      checkIsSqlBlockCell({
        cell: parentCell,
        state,
        hexVersionId,
      });

    if (isSqlBlockCell) {
      return resolveCellLocation({
        state,
        hexVersionId,
        location: {
          type: "relative",
          targetCellId: parentCell.id,
          position: location.position,
        },
        dereferenceComponentImportCellChildren,
        dereferenceSqlBlockCellChildren,
      });
    }
  }

  const sortedCells = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectFlattenedSorted(state);

  const siblingCells = sortedCells.filter(
    (c) =>
      c.parentCellId === parentCellId ||
      (c.parentCellId == null && parentCellId == null),
  );
  const targetCellIndex = siblingCells.findIndex((c) => c.id === targetCell.id);

  if (targetCellIndex === -1) {
    throw new Error(
      `Unable to find target cell ${location.targetCellId} in sibling cells`,
    );
  }

  const order =
    location.position === "before"
      ? fractionalIndexMidpoint95(
          siblingCells[targetCellIndex - 1]?.order ?? FRACTIONAL_INDEX_START,
          targetCell.order,
        )
      : fractionalIndexMidpoint95(
          targetCell.order,
          siblingCells[targetCellIndex + 1]?.order ?? FRACTIONAL_INDEX_END,
        );

  return {
    order,
    parentCellId,
    parentBlockCellId: parentCell?.blockCellId ?? null,
    parentComponentImportCellId: null,
  };
}

function resolveChildCellLocation({
  dereferenceComponentImportCellChildren = true,
  dereferenceSqlBlockCellChildren = true,
  hexVersionId,
  location,
  state,
}: ResolveCellCommonArgs & {
  location: ChildCellLocation;
}): ResolvedCellLocation {
  const parentCell =
    location.parentCellId != null
      ? hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, location.parentCellId)
      : undefined;

  if (parentCell != null) {
    const componentImportCell = dereferenceComponentImportCellChildren
      ? recursivelyFindComponentImportCell({
          cell: parentCell,
          state,
          hexVersionId,
        })
      : null;

    if (componentImportCell != null) {
      return resolveCellLocation({
        state,
        hexVersionId,
        location: {
          type: "relative",
          targetCellId: componentImportCell.id,
          position: location.position === "first" ? "before" : "after",
        },
        dereferenceComponentImportCellChildren,
        dereferenceSqlBlockCellChildren,
      });
    }

    const isSqlBlockCell =
      dereferenceSqlBlockCellChildren &&
      checkIsSqlBlockCell({
        cell: parentCell,
        state,
        hexVersionId,
      });

    if (isSqlBlockCell) {
      return resolveCellLocation({
        state,
        hexVersionId,
        location: {
          type: "relative",
          targetCellId: parentCell.id,
          position: location.position === "first" ? "before" : "after",
        },
        dereferenceComponentImportCellChildren,
        dereferenceSqlBlockCellChildren,
      });
    }
  }

  const sortedCells = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectFlattenedSorted(state);
  const siblingCells = sortedCells.filter(
    (c) =>
      c.parentCellId === location.parentCellId ||
      (location.parentCellId == null && c.parentCellId == null),
  );

  if (siblingCells.length === 0) {
    return {
      order: initialMidpoint95,
      parentCellId: location.parentCellId,
      parentBlockCellId: parentCell?.blockCellId ?? null,
      parentComponentImportCellId: null,
    };
  } else if (location.position === "first") {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: siblingCells[0].id,
        position: "before",
      },
      dereferenceComponentImportCellChildren,
      dereferenceSqlBlockCellChildren,
    });
  } else {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: siblingCells[siblingCells.length - 1].id,
        position: "after",
      },
      dereferenceComponentImportCellChildren,
      dereferenceSqlBlockCellChildren,
    });
  }
}

function resolveGlobalCellLocation({
  hexVersionId,
  location,
  state,
  ...args
}: ResolveCellCommonArgs & {
  location: GlobalCellLocation;
}): ResolvedCellLocation {
  return resolveCellLocation({
    hexVersionId,
    location: normalizeCellLocation({ hexVersionId, location, state }),
    state,
    ...args,
  });
}

function recursivelyFindComponentImportCell({
  cell,
  hexVersionId,
  state,
}: {
  cell: CellMP;
  hexVersionId: HexVersionId;
  state: RootState;
}): CellMP | null {
  if (cell.cellType === CellType.COMPONENT_IMPORT) {
    return cell;
  } else if (cell.parentCellId) {
    const parentCell = hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectById(state, cell.parentCellId);

    if (parentCell == null) {
      throw new Error(`Unable to find parent cell ${cell.parentCellId}`);
    }
    return recursivelyFindComponentImportCell({
      cell: parentCell,
      hexVersionId,
      state,
    });
  } else {
    return null;
  }
}

function checkIsSqlBlockCell({
  cell,
  hexVersionId,
  state,
}: {
  cell: CellMP;
  hexVersionId: HexVersionId;
  state: RootState;
}): boolean {
  if (cell.cellType === CellType.BLOCK) {
    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellId(state, cell.id);

    if (cellContents == null) {
      throw new Error(`Unable to find cell contents ${cell.id}`);
    }

    if (
      cellContents.__typename === "BlockCell" &&
      SQLCellBlockConfig.guard(cellContents.blockConfig)
    ) {
      return true;
    }
  }

  return false;
}

export function dereferenceSqlBlockCellChildId({
  cellId,
  hexVersionId,
  state,
}: {
  cellId: CellId;
  hexVersionId: HexVersionId;
  state: RootState;
}): CellId {
  const cell = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectById(state, cellId);

  const parentCell =
    cell?.parentCellId != null
      ? hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, cell.parentCellId)
      : undefined;

  if (parentCell == null) {
    return cellId;
  }

  const isSqlBlockCell = checkIsSqlBlockCell({
    cell: parentCell,
    hexVersionId,
    state,
  });

  return isSqlBlockCell ? parentCell.id : cellId;
}

export function normalizeCellLocation({
  hexVersionId,
  location,
  state,
}: {
  hexVersionId: HexVersionId;
  location: CellLocation;
  state: RootState;
}): RelativeCellLocation | ChildCellLocation {
  if (location.type !== "global") {
    return location;
  }

  const firstSelectedCellId = cellSelectionSelectors.selectSortedSelectedCells(
    state,
    hexVersionId,
  )[0]?.id;

  if (firstSelectedCellId != null) {
    return {
      type: "relative",
      targetCellId: firstSelectedCellId,
      position: "after",
    };
  } else {
    return {
      type: "child",
      parentCellId: null,
      position: "last",
    };
  }
}
