import {
  CellId,
  GraphNode,
  HexVersionId,
  HexVersionSecretId,
  SecretId,
  SecretName,
  asciiCompare,
  getCellGraph,
  typedObjectEntries,
  typedObjectKeys,
} from "@hex/common";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { isEqual } from "lodash";
import memoize from "micro-memoize";

import { getCellReferenceMap } from "../../graph/getCellReferences";
import { getSelectorsForEntityState } from "../utils/entityAdapterSelectorCreator";

import { CellContentsMP, CellMP } from "./hexVersionMPSlice";

export type CellGraphSliceValue = {
  graphNodes: EntityState<GraphNode<CellMP>>;
  cachedSecretNames: Record<SecretId, SecretName>;
};

type UpdateCellGraphPayload = {
  hexVersionId: HexVersionId;
  cells: Record<CellId, CellMP | undefined>;
  cellContents: Record<CellId, CellContentsMP | undefined>;
  secretNames: SecretName[];
};

type UpdateCachedSecretNamesPayload = {
  hexVersionId: HexVersionId;
  names: Record<HexVersionSecretId, SecretName>;
};

const graphNodeAdapter = createEntityAdapter<GraphNode<CellMP>>({
  selectId: (node) => node.cell.id,
  sortComparer: (a, b) => asciiCompare(a.cell.order, b.cell.order),
});

export const initialCellGraphValueState: CellGraphSliceValue = {
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  graphNodes: graphNodeAdapter.getInitialState(),
  cachedSecretNames: {},
};

const cellGraphValueSlice = createSlice({
  name: "cellGraph",
  initialState: initialCellGraphValueState,
  reducers: {
    updateCellGraph: (state, action: PayloadAction<UpdateCellGraphPayload>) => {
      const { cellContents, cells, secretNames } = action.payload;

      const [cellMap] = getCellReferenceMap(cells, cellContents);
      const { graph } = getCellGraph({
        cells: cellMap,
        secrets: secretNames,
      });

      const cellIds = new Set<EntityId>(Object.keys(graph));

      // Remove any nodes that are no longer in the graph
      for (const cellId of state.graphNodes.ids) {
        if (!cellIds.has(cellId)) {
          graphNodeAdapter.removeOne(state.graphNodes, cellId);
        }
      }

      const props: Record<keyof GraphNode<CellMP>, null> = {
        inputParams: null,
        cell: null,
        outputParams: null,
      };

      // Update nodes if they have changed, manually checking each field because upsertOne only does shallow compare
      for (const [cellId, node] of typedObjectEntries(graph)) {
        const oldNode = state.graphNodes.entities[cellId];
        if (!oldNode) {
          graphNodeAdapter.upsertOne(state.graphNodes, node);
        } else {
          for (const prop of typedObjectKeys(props)) {
            if (!isEqual(oldNode[prop], node[prop])) {
              graphNodeAdapter.updateOne(state.graphNodes, {
                id: node.cell.id,
                changes: { [prop]: node[prop] },
              });
            }
          }
        }
      }
    },
    updateCachedSecretNames: (
      state,
      action: PayloadAction<UpdateCachedSecretNamesPayload>,
    ) => {
      state.cachedSecretNames = action.payload.names;
    },
  },
});

export const cellGraphSelectors = {
  getGraphNodeSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityState(
      graphNodeAdapter,
      (state) => state.cellGraph[hexVersionId]?.graphNodes,
    );

    return { ...baseSelectors };
  }),
};

export const { updateCellGraph } = cellGraphValueSlice.actions;

export const cellGraphActions = {
  ...cellGraphValueSlice.actions,
} as const;

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(cellGraphActions).map((a) => a.type),
);

type CellGraphActions = typeof cellGraphValueSlice.actions;
type CellGraphActionPayload = Parameters<
  CellGraphActions[keyof CellGraphActions]
>[0];

type CellGraphSliceState = {
  [hexVersionId: string]: CellGraphSliceValue | undefined;
};

export const cellGraphReducer = createReducer<CellGraphSliceState>(
  {},
  (builder) =>
    builder.addMatcher(
      (
        action,
      ): action is PayloadAction<
        CellGraphActionPayload | UpdateCachedSecretNamesPayload
      > => allActionTypes.has(action.type),
      (state, action) => {
        state[action.payload.hexVersionId] = castDraft(
          cellGraphValueSlice.reducer(
            state[action.payload.hexVersionId],
            action,
          ),
        );
      },
    ),
);
