import {
  CanvasElementId,
  CellId,
  CellType,
  ColumnPropertiesUpdateableFields,
  DataConnectionId,
  DataSourceDbtMetricId,
  DisplayTableColumnId,
  GridElementEntityId,
  GridRowId,
  HEX_USER_ATTRIBUTES_PARAMETER,
  HexId,
  HexVersionId,
  MagicEventId,
  OmitRecursively,
  SQLCellBlockConfig,
  SharedFilterId,
  StaticCellId,
  asciiCompare,
  assertNever,
  filterSortedCellsChildren,
  flattenSortedCells,
  getDateTimeString,
  notEmpty,
  sortCells,
  stableEmptyArray,
  typedObjectFromEntries,
} from "@hex/common";
import {
  EntityState,
  PayloadAction,
  createDraftSafeSelector,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { groupBy, keyBy } from "lodash";
import memoize from "micro-memoize";

import { getDisplayTableConfigFromCellContents } from "../../hex-version-multiplayer/handlers/displayTableConfigClientHandlers.js";
import { HexVersionAppMpFragment } from "../../hex-version-multiplayer/HexVersionAppMPModel.generated";
import {
  BlockCellMpFragment,
  CanvasElementMpFragment,
  CellMpFragment,
  ChartCellMpFragment,
  CodeCellMpFragment,
  CollapsibleCellMpFragment,
  ComponentImportCellMpFragment,
  DataConnectionHexVersionLinkMpFragment,
  DbtMetricCellMpFragment,
  DisplayTableCellMpFragment,
  ExploreCellMpFragment,
  ExternalFileIntegrationHexVersionLinkMpFragment,
  FileHexVersionLinkMpFragment,
  FileMpFragment,
  FilterCellMpFragment,
  GridLayoutMpFragment,
  GridRowMpFragment,
  HexVersionMpFragment,
  HexVersionSecretMpFragment,
  InputCellMpFragment,
  MagicEventFragment,
  MapCellMpFragment,
  MarkdownCellMpFragment,
  MetricCellMpFragment,
  PivotCellMpFragment,
  SecretMpFragment,
  SqlCellMpFragment,
  StoryElementMpFragment,
  SyncRepositoryHexVersionLinkMpFragment,
  TextCellMpFragment,
  VcsPackageHexVersionLinkMpFragment,
  VegaChartCellMpFragment,
  WritebackCellMpFragment,
} from "../../hex-version-multiplayer/HexVersionMPModel.generated";
import { resolveCellLabel } from "../../util/cellLayoutHelpers.js";
import type { RootState } from "../store";
import {
  getSelectorsForEntityState,
  getSelectorsForEntityStateWithSoftDelete,
} from "../utils/entityAdapterSelectorCreator";
import { KeysOfUnion, assertNonNull } from "../utils/types";

//#region action helper types
type WithHexVersionId<T> = {
  hexVersionId: HexVersionId;
  data: T;
};
export type HexVersionAction<D, T extends string = string> = PayloadAction<
  WithHexVersionId<D>,
  T
>;

// Helpful for magic cell state actions since they always require a cell id
type WithHexVersionAndCellIdData<T> = {
  hexVersionId: HexVersionId;
  cellId: CellId;
  data: T;
};
export type HexVersionAndCellDataAction<
  D,
  T extends string = string,
> = PayloadAction<WithHexVersionAndCellIdData<D>, T>;

type WithHexVersionAndCellId = {
  hexVersionId: HexVersionId;
  cellId: CellId;
};
export type HexVersionAndCellAction = PayloadAction<WithHexVersionAndCellId>;

type SetFieldAction<T> = HexVersionAction<{
  key: keyof NonNullable<T>;
  value: unknown;
}>;
type SetEntityFieldPayload<T extends { id: string }> = {
  entityId: T["id"];
  key: keyof NonNullable<T>;
  value: unknown;
};
type SetEntityFieldAction<T extends { id: string }> = HexVersionAction<
  SetEntityFieldPayload<T>
>;

//#region types of data in redux store
export type CanvasElementMP = CanvasElementMpFragment;
export type CellMP = Omit<
  CellMpFragment,
  | "canvasElements"
  | "cellContents"
  | "gridElements"
  | "storyElement"
  | "hexVersion"
  | "latestMagicEvent"
>;
type WithoutTypenames<T, F extends keyof T> = Omit<T, F> &
  OmitRecursively<Pick<T, F>, "__typename">;
// We have to manually reconstruct the CellContentsMP because we need to remove the `__typename` field from cellReferencesV2
// In order to protect against accidentally using the field.
// The reason the field is not reliable, is that it is properly set when using `initializeFromHexVersionMPData` as those are loaded over GQL
// But when using `setCellContentsField` which comes from MP, the operation payload does not contain the __typename field.
type SimplifiedGQLFields = "cellReferencesV2" | "cellReferencesParseError";
export type SafeCodeCellMpFragment = WithoutTypenames<
  CodeCellMpFragment,
  SimplifiedGQLFields
>;
export type SafeMarkdownCellMpFragment = WithoutTypenames<
  MarkdownCellMpFragment,
  SimplifiedGQLFields
>;
export type SafeMetricCellMpFragment = WithoutTypenames<
  MetricCellMpFragment,
  SimplifiedGQLFields
>;
export type SafeSqlCellMpFragment = Omit<
  WithoutTypenames<SqlCellMpFragment, SimplifiedGQLFields>,
  "connectionV2"
> & {
  connectionId: DataConnectionId | undefined;
};
export type SafeTextCellMpFragment = WithoutTypenames<
  TextCellMpFragment,
  SimplifiedGQLFields
>;
export type SafeWritebackCellMpFragment = Omit<
  WritebackCellMpFragment,
  "connection"
> & {
  connectionId: DataConnectionId | undefined;
  connectionName: string | undefined;
};
export type SafeDbtMetricCellMpFragment = Omit<
  DbtMetricCellMpFragment,
  "connection" | "metrics"
> & {
  connectionId: DataConnectionId | undefined;
  metricIds: DataSourceDbtMetricId[];
};

export type CellContentsMP = (
  | DisplayTableCellMpFragment
  | InputCellMpFragment
  | VegaChartCellMpFragment
  | MapCellMpFragment
  | PivotCellMpFragment
  | FilterCellMpFragment
  | ComponentImportCellMpFragment
  | SafeCodeCellMpFragment
  | SafeMarkdownCellMpFragment
  | SafeMetricCellMpFragment
  | SafeSqlCellMpFragment
  | SafeTextCellMpFragment
  | SafeWritebackCellMpFragment
  | SafeDbtMetricCellMpFragment
  | ChartCellMpFragment
  | BlockCellMpFragment
  | ExploreCellMpFragment
  | CollapsibleCellMpFragment
) & {
  cellId: CellId;
};
export type ResolvedCellMP = CellMP & { cellContents: CellContentsMP };
export type MagicEventMP = MagicEventFragment;
export type FileMP = FileMpFragment;
export type GridLayoutMP = Omit<GridLayoutMpFragment, "gridRows">;
export type HexVersionMP = Omit<
  HexVersionMpFragment,
  | "canvasLayout"
  | "cells"
  | "files"
  | "secrets"
  | "magicEvents"
  | "dataConnectionHexVersionLinks"
  | "fileHexVersionLinks"
  | "hexVersionSecrets"
  | "syncRepositoryHexVersionLinks"
  | "vcsPackageHexVersionLinks"
  | "storyLayout"
  | "gridLayouts"
  | "externalFileIntegrationHexVersionLinks"
  | "sharedFilters"
> & {
  canvasLayout: Omit<HexVersionMpFragment["canvasLayout"], "canvasElements">;
  storyLayout: Omit<HexVersionMpFragment["storyLayout"], "storyElements">;
};
export type ExploreMP = HexVersionMP["explore"];
export type CanvasLayoutMP = HexVersionMP["canvasLayout"];
export type SecretMP = SecretMpFragment;
export type DataConnectionHexVersionLinkMP =
  DataConnectionHexVersionLinkMpFragment;
export type ExternalFileIntegrationHexVersionLinkMP =
  ExternalFileIntegrationHexVersionLinkMpFragment;
export type FileHexVersionLinkMP = FileHexVersionLinkMpFragment;
export type HexVersionSecretMP = HexVersionSecretMpFragment;
export type SyncRepositoryHexVersionLinkMP =
  SyncRepositoryHexVersionLinkMpFragment;
export type VcsPackageHexVersionLinkMP = VcsPackageHexVersionLinkMpFragment;
export type StoryLayoutMP = HexVersionMP["storyLayout"];
export type StoryElementMP = StoryElementMpFragment & {
  cell: NonNullable<StoryElementMpFragment["cell"]>;
};
export type GridRowMP = GridRowMpFragment;
export type GridColumnMP = GridRowMP["gridColumns"][number];
export type GridElementMP = GridColumnMP["gridElements"][number];
export type SharedFilterMP = HexVersionMpFragment["sharedFilters"][number];

//#region adapters for normalization of lists of elements
const canvasElementAdapter = createEntityAdapter<CanvasElementMP>({
  selectId: (ele) => ele.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const cellAdapter = createEntityAdapter<CellMP>({
  selectId: (cell) => cell.id,
  sortComparer: (a, b) => asciiCompare(a.order, b.order),
});
const cellContentsAdapter = createEntityAdapter<CellContentsMP>({
  //  cell contents are keyed on the cell ID since they're often accessed in context of a specific cell
  selectId: (cellContents) => cellContents.cellId,
  // order doesn't really matter, but sort just to keep things consistent
  sortComparer: (a, b) => asciiCompare(a.cellId, b.cellId),
});
const magicEventAdapter = createEntityAdapter<MagicEventMP>({
  selectId: (magicEvent) => magicEvent.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const filesAdapter = createEntityAdapter<FileMP>({
  selectId: (file) => file.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const secretsAdapter = createEntityAdapter<SecretMP>({
  selectId: (secret) => secret.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const dataConnectionHexVersionLinksAdapter =
  createEntityAdapter<DataConnectionHexVersionLinkMP>({
    selectId: (dataConnectionHexVersionLink) => dataConnectionHexVersionLink.id,
    sortComparer: (a, b) => asciiCompare(a.id, b.id),
  });
const fileHexVersionLinksAdapter = createEntityAdapter<FileHexVersionLinkMP>({
  selectId: (fileHexVersionLink) => fileHexVersionLink.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const hexVersionSecretsAdapter = createEntityAdapter<HexVersionSecretMP>({
  selectId: (hexVersionSecret) => hexVersionSecret.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const syncRepositoryHexVersionLinksAdapter =
  createEntityAdapter<SyncRepositoryHexVersionLinkMP>({
    selectId: (syncRepositoryHexVersionLink) => syncRepositoryHexVersionLink.id,
    sortComparer: (a, b) => asciiCompare(a.id, b.id),
  });
const vcsPackageHexVersionLinksAdapter =
  createEntityAdapter<VcsPackageHexVersionLinkMP>({
    selectId: (vcsPackageHexVersionLink) => vcsPackageHexVersionLink.id,
    sortComparer: (a, b) => asciiCompare(a.id, b.id),
  });
const storyElementAdapter = createEntityAdapter<StoryElementMP>({
  // story elements are keyed on the cell ID since they're often accessed in context of a specific cell
  selectId: (ele) => ele.cell.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});
const gridLayoutAdapter = createEntityAdapter<GridLayoutMP>({
  selectId: (layout) => layout.id,
  sortComparer: (a, b) => asciiCompare(a.order, b.order),
});
const gridRowAdapter = createEntityAdapter<GridRowMP>({
  selectId: (row) => row.id,
  sortComparer: (a, b) => asciiCompare(a.order, b.order),
});
const externalFileIntegrationHexVersionLinksAdapter =
  createEntityAdapter<ExternalFileIntegrationHexVersionLinkMP>({
    selectId: (externalFileIntegrationHexVersionLink) =>
      externalFileIntegrationHexVersionLink.id,
    sortComparer: (a, b) => asciiCompare(a.id, b.id),
  });
const sharedFiltersAdapter = createEntityAdapter<SharedFilterMP>({
  selectId: (sf) => sf.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});

//#region reducer for data for a single HexVersionMP instance

// main shape of state
export type HexVersionMPValue = {
  canvasElements: EntityState<CanvasElementMP>;
  cells: EntityState<CellMP>;
  cellContents: EntityState<CellContentsMP>;
  files: EntityState<FileMP>;
  hexVersion: HexVersionMP;
  secrets: EntityState<SecretMP>;
  dataConnectionHexVersionLinks: EntityState<DataConnectionHexVersionLinkMP>;
  fileHexVersionLinks: EntityState<FileHexVersionLinkMP>;
  hexVersionSecrets: EntityState<HexVersionSecretMP>;
  syncRepositoryHexVersionLinks: EntityState<SyncRepositoryHexVersionLinkMP>;
  vcsPackageHexVersionLinks: EntityState<VcsPackageHexVersionLinkMP>;
  storyElements: EntityState<StoryElementMP>;
  staticCellIdToCellId: Record<StaticCellId, CellId>;
  cellContentsIdToCellId: Record<string, CellId>;
  gridRows: EntityState<GridRowMP>;
  gridLayouts: EntityState<GridLayoutMP>;
  externalFileIntegrationHexVersionLinks: EntityState<ExternalFileIntegrationHexVersionLinkMP>;
  sharedFilters: EntityState<SharedFilterMP>;
  magicEvents: EntityState<MagicEventMP>;
};

const hexVersionMPValueSlice = createSlice({
  name: "hexVersionMPValue",
  // This initial state is essentially meaningless,
  // Since this reducer is only called as a function directly by `hexVersionMPReducer` and
  // we always should have a `initializeFromHexVersionMPData` or `initializeFromHexVersionAppMPData` action to really initialize things.
  initialState: null as unknown as HexVersionMPValue,
  reducers: {
    // canvas element actions
    moveCanvasElement(
      state,
      action: HexVersionAction<{
        elementId: CanvasElementId;
        newPosition: {
          x: number;
          y: number;
          width: number;
          height: number;
        };
      }>,
    ) {
      canvasElementAdapter.updateOne(state.canvasElements, {
        id: action.payload.data.elementId,
        changes: action.payload.data.newPosition,
      });
    },
    setCanvasElementField(
      state,
      { payload: { data } }: SetEntityFieldAction<CanvasElementMpFragment>,
    ) {
      canvasElementAdapter.updateOne(state.canvasElements, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertCanvasElement(
      state,
      action: HexVersionAction<CanvasElementMpFragment>,
    ) {
      canvasElementAdapter.upsertOne(state.canvasElements, action.payload.data);
    },
    // cell actions
    moveCell(
      state,
      action: HexVersionAction<{
        cellId: CellId;
        parentCellId: CellId | undefined;
        insertAt: string;
      }>,
    ) {
      cellAdapter.updateOne(state.cells, {
        id: action.payload.data.cellId,
        changes: {
          order: action.payload.data.insertAt,
          parentCellId: action.payload.data.parentCellId ?? null,
        },
      });
    },
    setCellField(state, { payload: { data } }: SetEntityFieldAction<CellMP>) {
      cellAdapter.updateOne(state.cells, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertStoryElement(state, action: HexVersionAction<StoryElementMP>) {
      storyElementAdapter.upsertOne(state.storyElements, action.payload.data);
    },
    upsertCell(state, action: HexVersionAction<CellMpFragment>) {
      // remove data that gets normalized and inserted elsewhere
      const {
        cellContents,
        hexVersion: ___,
        ...cellData
      } = action.payload.data;
      cellAdapter.upsertOne(state.cells, cellData);

      const prevContents = state.cellContents.entities[cellData.id];

      if (
        prevContents != null &&
        prevContents.__typename !== cellContents.__typename
      ) {
        delete state.cellContentsIdToCellId[cellContentsMPToId(prevContents)];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (prevContents as any)[cellContentsMPToIdFieldName(prevContents)];
      }

      if (cellContents.__typename === "CellGroupCell") {
        return;
      }

      let cellContentsWithCellId: CellContentsMP;

      switch (cellContents.__typename) {
        case "SqlCell":
          cellContentsWithCellId = {
            cellId: cellData.id,
            ...cellContents,
            connectionId:
              cellContents.connectionV2?.__typename === "DataConnection"
                ? cellContents.connectionV2.id
                : cellContents.connectionV2?.__typename ===
                    "UnknownDataConnection"
                  ? cellContents.connectionV2.unknownDataConnectionId
                  : undefined,
          };
          break;
        case "WritebackCell":
          cellContentsWithCellId = {
            cellId: cellData.id,
            ...cellContents,
            connectionName:
              cellContents.connection?.__typename === "DataConnection"
                ? cellContents.connection.connectionName
                : undefined,
            connectionId:
              cellContents.connection?.__typename === "DataConnection"
                ? cellContents.connection.id
                : cellContents.connection?.__typename ===
                    "UnknownDataConnection"
                  ? cellContents.connection.unknownDataConnectionId
                  : undefined,
          };
          break;
        case "DbtMetricCell":
          cellContentsWithCellId = {
            cellId: cellData.id,
            ...cellContents,
            connectionId:
              cellContents.connection?.__typename === "DataConnection"
                ? cellContents.connection.id
                : cellContents.connection?.__typename ===
                    "UnknownDataConnection"
                  ? cellContents.connection.unknownDataConnectionId
                  : undefined,
            metricIds: cellContents.metrics.map((m) => m.id),
          };
          break;
        default:
          cellContentsWithCellId = {
            cellId: cellData.id,
            ...cellContents,
          };
      }

      cellContentsAdapter.upsertOne(state.cellContents, cellContentsWithCellId);

      state.staticCellIdToCellId[cellData.staticId] = cellData.id;
      state.cellContentsIdToCellId[cellContentsMPToId(cellContentsWithCellId)] =
        cellData.id;
    },
    // mark a cell and its contents as deleted
    deleteCell(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
      }>,
    ) {
      const currentTime = getDateTimeString(new Date());

      cellAdapter.updateOne(state.cells, {
        id: data.cellId,
        changes: { ["deletedDate"]: currentTime },
      });
      cellContentsAdapter.updateOne(state.cellContents, {
        id: data.cellId,
        changes: { ["deletedDate"]: currentTime },
      });
    },
    restoreCell(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
      }>,
    ) {
      cellAdapter.updateOne(state.cells, {
        id: data.cellId,
        changes: { ["deletedDate"]: null },
      });

      cellContentsAdapter.updateOne(state.cellContents, {
        id: data.cellId,
        changes: { ["deletedDate"]: null },
      });
    },
    // cell content actions
    setCellContentsField(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
        // "dataframeVariableName" has been removed from our hexVersion MP model, but we
        // still want to support updating this field on the server to be compatabile
        // with old client, so we are leaving "dataframeVariableName" as an allowed key
        // for UPDATE_DISPLAY_TABLE_CELL_OPERATION, which is why we need to allow it in
        // this type for type compatability
        key: KeysOfUnion<CellContentsMP> | "dataFrameVariableName";
        value: unknown;
      }>,
    ) {
      cellContentsAdapter.updateOne(state.cellContents, {
        id: data.cellId,
        changes: { [data.key]: data.value },
      });
    },
    upsertCellContents(state, action: HexVersionAction<CellContentsMP>) {
      const prevContents =
        state.cellContents.entities[action.payload.data.cellId];

      if (
        prevContents != null &&
        prevContents.__typename !== action.payload.data.__typename
      ) {
        delete state.cellContentsIdToCellId[cellContentsMPToId(prevContents)];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (prevContents as any)[cellContentsMPToIdFieldName(prevContents)];
      }

      cellContentsAdapter.upsertOne(state.cellContents, action.payload.data);
      state.cellContentsIdToCellId[cellContentsMPToId(action.payload.data)] =
        action.payload.data.cellId;
    },
    renameColumnPropertyForDisplayTable(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
        originalName: DisplayTableColumnId;
        newName: DisplayTableColumnId;
      }>,
    ) {
      const cellEntity = state.cellContents.entities[data.cellId];
      const displayTableConfig = castDraft(
        getDisplayTableConfigFromCellContents(cellEntity),
      );

      const matchingProperty = displayTableConfig?.columnProperties.find(
        (p) => p.originalName === data.originalName,
      );

      if (matchingProperty == null) {
        return;
      }
      matchingProperty.originalName = data.newName;
    },
    upsertColumnPropertyForDisplayTable(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
        originalName: DisplayTableColumnId;
        key: keyof ColumnPropertiesUpdateableFields;
        value: ColumnPropertiesUpdateableFields[keyof ColumnPropertiesUpdateableFields];
      }>,
    ) {
      const cellEntity = state.cellContents.entities[data.cellId];

      let displayTableConfig;
      if (cellEntity && "displayTableConfig" in cellEntity) {
        displayTableConfig = cellEntity.displayTableConfig;
      } else if (cellEntity && "sqlDisplayTableConfig" in cellEntity) {
        displayTableConfig = cellEntity.sqlDisplayTableConfig;
      } else if (cellEntity && "dbtMetricDisplayTableConfig" in cellEntity) {
        displayTableConfig = cellEntity.dbtMetricDisplayTableConfig;
      } else if (cellEntity && "chartDisplayTableConfig" in cellEntity) {
        displayTableConfig = cellEntity.chartDisplayTableConfig;
      }

      if (displayTableConfig == null) {
        throw new Error(
          `Cannot find display table config for cell ${data.cellId}`,
        );
      }

      const matchingProperty = displayTableConfig.columnProperties.find(
        (p) => p.originalName === data.originalName,
      );

      if (matchingProperty) {
        (matchingProperty as Record<string, unknown>)[data.key] = data.value;
      } else {
        displayTableConfig.columnProperties.push({
          __typename: "ColumnProperties",
          size: null,
          originalName: data.originalName,
          displayFormat: null,
          renameTo: null,
          revision: -1,
          wrapText: null,
          [data.key]: data.value,
        });
      }
    },
    // file actions
    setFileField(state, { payload: { data } }: SetEntityFieldAction<FileMP>) {
      filesAdapter.updateOne(state.files, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertFile(state, action: HexVersionAction<FileMP>) {
      filesAdapter.upsertOne(state.files, action.payload.data);
    },
    // secret actions
    setSecretField(
      state,
      { payload: { data } }: SetEntityFieldAction<SecretMP>,
    ) {
      secretsAdapter.updateOne(state.secrets, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertSecret(state, action: HexVersionAction<SecretMP>) {
      secretsAdapter.upsertOne(state.secrets, action.payload.data);
    },
    // editorUserAttributesOverride actions
    setEditorUserAttributesOverride(
      state,
      {
        payload: {
          data: { key, value },
        },
      }: HexVersionAction<{
        key: string;
        value: string | null;
      }>,
    ) {
      if (!state.hexVersion.editorUserAttributesOverride) {
        state.hexVersion.editorUserAttributesOverride = {};
      }
      if (value != null) {
        state.hexVersion.editorUserAttributesOverride[key] = value;
      } else {
        delete state.hexVersion.editorUserAttributesOverride[key];
      }
    },
    // hex version secret actions
    setHexVersionSecretField(
      state,
      { payload: { data } }: SetEntityFieldAction<HexVersionSecretMP>,
    ) {
      hexVersionSecretsAdapter.updateOne(state.hexVersionSecrets, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertHexVersionSecret(
      state,
      action: HexVersionAction<HexVersionSecretMP>,
    ) {
      hexVersionSecretsAdapter.upsertOne(
        state.hexVersionSecrets,
        action.payload.data,
      );
    },
    // magic event actions
    setMagicEventField(
      state,
      { payload: { data } }: SetEntityFieldAction<MagicEventMP>,
    ) {
      magicEventAdapter.updateOne(state.magicEvents, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertMagicEvent(state, action: HexVersionAction<MagicEventMP>) {
      magicEventAdapter.upsertOne(state.magicEvents, action.payload.data);
    },
    // VcsPackageHexVersionLink actions
    setVcsPackageHexVersionLinkField(
      state,
      { payload: { data } }: SetEntityFieldAction<VcsPackageHexVersionLinkMP>,
    ) {
      vcsPackageHexVersionLinksAdapter.updateOne(
        state.vcsPackageHexVersionLinks,
        {
          id: data.entityId,
          changes: { [data.key]: data.value },
        },
      );
    },
    upsertVcsPackageHexVersionLink(
      state,
      action: HexVersionAction<VcsPackageHexVersionLinkMP>,
    ) {
      vcsPackageHexVersionLinksAdapter.upsertOne(
        state.vcsPackageHexVersionLinks,
        action.payload.data,
      );
    },
    // SyncRepositoryHexVersionLink actions
    setSyncRepositoryHexVersionLinkField(
      state,
      {
        payload: { data },
      }: SetEntityFieldAction<SyncRepositoryHexVersionLinkMP>,
    ) {
      syncRepositoryHexVersionLinksAdapter.updateOne(
        state.syncRepositoryHexVersionLinks,
        {
          id: data.entityId,
          changes: { [data.key]: data.value },
        },
      );
    },
    upsertSyncRepositoryHexVersionLink(
      state,
      action: HexVersionAction<SyncRepositoryHexVersionLinkMP>,
    ) {
      syncRepositoryHexVersionLinksAdapter.upsertOne(
        state.syncRepositoryHexVersionLinks,
        action.payload.data,
      );
    },
    setDataConnectionHexVersionLinkField(
      state,
      {
        payload: { data },
      }: SetEntityFieldAction<DataConnectionHexVersionLinkMP>,
    ) {
      dataConnectionHexVersionLinksAdapter.updateOne(
        state.dataConnectionHexVersionLinks,
        {
          id: data.entityId,
          changes: { [data.key]: data.value },
        },
      );
    },
    upsertDataConnectionHexVersionLink(
      state,
      action: HexVersionAction<DataConnectionHexVersionLinkMP>,
    ) {
      dataConnectionHexVersionLinksAdapter.upsertOne(
        state.dataConnectionHexVersionLinks,
        action.payload.data,
      );
    },
    setExternalFileIntegrationHexVersionLinkField(
      state,
      {
        payload: { data },
      }: SetEntityFieldAction<ExternalFileIntegrationHexVersionLinkMP>,
    ) {
      externalFileIntegrationHexVersionLinksAdapter.updateOne(
        state.externalFileIntegrationHexVersionLinks,
        {
          id: data.entityId,
          changes: { [data.key]: data.value },
        },
      );
    },
    upsertExternalFileIntegrationHexVersionLink(
      state,
      action: HexVersionAction<ExternalFileIntegrationHexVersionLinkMP>,
    ) {
      externalFileIntegrationHexVersionLinksAdapter.upsertOne(
        state.externalFileIntegrationHexVersionLinks,
        action.payload.data,
      );
    },
    setFileHexVersionLinkField(
      state,
      { payload: { data } }: SetEntityFieldAction<FileHexVersionLinkMP>,
    ) {
      fileHexVersionLinksAdapter.updateOne(state.fileHexVersionLinks, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertFileHexVersionLink(
      state,
      action: HexVersionAction<FileHexVersionLinkMP>,
    ) {
      fileHexVersionLinksAdapter.upsertOne(
        state.fileHexVersionLinks,
        action.payload.data,
      );
    },
    // hex version actions (including story, grid, and canvas layout)
    setCanvasLayoutField(state, action: SetFieldAction<CanvasLayoutMP>) {
      const canvasLayout: CanvasLayoutMP = state.hexVersion.canvasLayout;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (canvasLayout as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    setGridLayoutField(
      state,
      { payload: { data } }: SetEntityFieldAction<GridLayoutMP>,
    ) {
      gridLayoutAdapter.updateOne(state.gridLayouts, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertGridLayout(state, action: HexVersionAction<GridLayoutMP>) {
      gridLayoutAdapter.upsertOne(state.gridLayouts, action.payload.data);
    },
    setHexVersionField(state, action: SetFieldAction<HexVersionMP>) {
      const hexVersion: HexVersionMP = state.hexVersion;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (hexVersion as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    setExploreField(state, action: SetFieldAction<ExploreMP>) {
      const explore = state.hexVersion.explore;
      if (!explore) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (explore as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    // story element actions
    setStoryElementField(
      state,
      {
        payload: { data },
      }: HexVersionAction<{
        cellId: CellId;
        key: KeysOfUnion<StoryElementMP>;
        value: unknown;
      }>,
    ) {
      storyElementAdapter.updateOne(state.storyElements, {
        id: data.cellId,
        changes: { [data.key]: data.value },
      });
    },
    upsertSharedFilter(state, action: HexVersionAction<SharedFilterMP>) {
      sharedFiltersAdapter.upsertOne(state.sharedFilters, action.payload.data);
    },
    deleteSharedFilter(
      state,
      action: HexVersionAction<{
        id: SharedFilterId;
      }>,
    ) {
      sharedFiltersAdapter.updateOne(state.sharedFilters, {
        id: action.payload.data.id,
        changes: {
          deletedDate: getDateTimeString(new Date()),
        },
      });
    },
    // grid actions
    setGridRowField(
      state,
      { payload: { data } }: SetEntityFieldAction<GridRowMP>,
    ) {
      gridRowAdapter.updateOne(state.gridRows, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    upsertGridRow(state, action: HexVersionAction<GridRowMP>) {
      gridRowAdapter.upsertOne(state.gridRows, action.payload.data);
    },
    // main initialization actions
    initializeFromHexVersionMPData(
      _state,
      action: HexVersionAction<HexVersionMpFragment>,
    ) {
      const {
        canvasLayout: { canvasElements, ...canvasLayout },
        cells: rawCells,
        dataConnectionHexVersionLinks,
        externalFileIntegrationHexVersionLinks,
        fileHexVersionLinks,
        files,
        gridLayouts: rawGridLayouts,
        hexVersionSecrets,
        magicEvents,
        secrets,
        sharedFilters,
        storyLayout: { storyElements, ...storyLayout },
        syncRepositoryHexVersionLinks,
        vcsPackageHexVersionLinks,
        ...hexVersionData
      } = action.payload.data;

      const cells = rawCells.map(
        ({ cellContents: ____, hexVersion: ______, ...cell }) => cell,
      );
      const cellContents: CellContentsMP[] = rawCells
        .map((cell) => ({
          cellId: cell.id,
          ...cell.cellContents,
        }))
        .map((cellContent) => {
          switch (cellContent.__typename) {
            case "SqlCell":
              return {
                ...cellContent,
                connectionId:
                  cellContent.connectionV2?.__typename === "DataConnection"
                    ? cellContent.connectionV2.id
                    : cellContent.connectionV2?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connectionV2.unknownDataConnectionId
                      : undefined,
              };
            case "WritebackCell":
              return {
                ...cellContent,
                connectionName:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.connectionName
                    : undefined,
                connectionId:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.id
                    : cellContent.connection?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connection.unknownDataConnectionId
                      : undefined,
              };
            case "DbtMetricCell":
              return {
                ...cellContent,
                connectionId:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.id
                    : cellContent.connection?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connection.unknownDataConnectionId
                      : undefined,
                metricIds: cellContent.metrics.map((metric) => metric.id),
              };
            case "CellGroupCell":
              // TODO: temp hack to filter out CellGroupCells until fully removed from backend
              return undefined;
            default:
              return cellContent;
          }
        })
        .filter(notEmpty);
      const gridLayouts = rawGridLayouts.map(
        ({ gridRows: ________, ...gridLayout }) => gridLayout,
      );

      const gridRows = rawGridLayouts.flatMap((layout) => layout.gridRows);

      // Add cell specific magic events, setAll handles deduping
      const allMagicEvents = Array.from(magicEvents);
      cells.forEach((cell) => {
        if (cell.latestMagicEvent) {
          allMagicEvents.push({
            ...cell.latestMagicEvent,
          });
        }
      });

      return {
        canvasElements: canvasElementAdapter.setAll(
          canvasElementAdapter.getInitialState(),
          canvasElements,
        ),
        cells: cellAdapter.setAll(cellAdapter.getInitialState(), cells),
        cellContents: cellContentsAdapter.setAll(
          cellContentsAdapter.getInitialState(),
          cellContents,
        ),
        files: filesAdapter.setAll(filesAdapter.getInitialState(), files),
        hexVersion: {
          canvasLayout,
          storyLayout,
          ...hexVersionData,
        },
        gridLayouts: gridLayoutAdapter.setAll(
          gridLayoutAdapter.getInitialState(),
          gridLayouts,
        ),
        secrets: secretsAdapter.setAll(
          secretsAdapter.getInitialState(),
          secrets,
        ),
        hexVersionSecrets: hexVersionSecretsAdapter.setAll(
          hexVersionSecretsAdapter.getInitialState(),
          hexVersionSecrets,
        ),
        magicEvents: magicEventAdapter.setAll(
          magicEventAdapter.getInitialState(),
          allMagicEvents,
        ),
        vcsPackageHexVersionLinks: vcsPackageHexVersionLinksAdapter.setAll(
          vcsPackageHexVersionLinksAdapter.getInitialState(),
          vcsPackageHexVersionLinks,
        ),
        syncRepositoryHexVersionLinks:
          syncRepositoryHexVersionLinksAdapter.setAll(
            syncRepositoryHexVersionLinksAdapter.getInitialState(),
            syncRepositoryHexVersionLinks,
          ),
        dataConnectionHexVersionLinks:
          dataConnectionHexVersionLinksAdapter.setAll(
            dataConnectionHexVersionLinksAdapter.getInitialState(),
            dataConnectionHexVersionLinks,
          ),
        fileHexVersionLinks: fileHexVersionLinksAdapter.setAll(
          fileHexVersionLinksAdapter.getInitialState(),
          fileHexVersionLinks,
        ),
        externalFileIntegrationHexVersionLinks:
          externalFileIntegrationHexVersionLinksAdapter.setAll(
            externalFileIntegrationHexVersionLinksAdapter.getInitialState(),
            externalFileIntegrationHexVersionLinks,
          ),
        storyElements: storyElementAdapter.setAll(
          storyElementAdapter.getInitialState(),
          storyElements.filter((s): s is StoryElementMP => s.cell != null),
        ),
        staticCellIdToCellId: cells.reduce<Record<StaticCellId, CellId>>(
          (acc, curr) => {
            acc[curr.staticId] = curr.id;
            return acc;
          },
          {},
        ),
        cellContentsIdToCellId: cellContents.reduce<Record<string, CellId>>(
          (acc, contents) => {
            acc[cellContentsMPToId(contents)] = contents.cellId;
            return acc;
          },
          {},
        ),
        gridRows: gridRowAdapter.setAll(
          gridRowAdapter.getInitialState(),
          gridRows,
        ),
        sharedFilters: sharedFiltersAdapter.setAll(
          sharedFiltersAdapter.getInitialState(),
          sharedFilters,
        ),
      };
    },
    initializeFromHexVersionAppMPData(
      _state,
      action: HexVersionAction<HexVersionAppMpFragment>,
    ) {
      const {
        canvasLayout: canvasLayoutAndElements,
        cellsUsedInApp: rawCells,
        gridLayouts: rawGridLayouts,
        storyLayout: storyLayoutAndElements,
        ...hexVersionData
      } = action.payload.data;

      const cells = rawCells.map(
        ({ cellContents: ____, hexVersion: ______, ...cell }) => cell,
      );

      const cellContents: CellContentsMP[] = rawCells
        .map((cell) => ({
          cellId: cell.id,
          ...cell.cellContents,
        }))
        .map((cellContent) => {
          switch (cellContent.__typename) {
            case "SqlCell":
              return {
                ...cellContent,
                connectionId:
                  cellContent.connectionV2?.__typename === "DataConnection"
                    ? cellContent.connectionV2.id
                    : cellContent.connectionV2?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connectionV2.unknownDataConnectionId
                      : undefined,
              };
            case "WritebackCell":
              return {
                ...cellContent,
                connectionName:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.connectionName
                    : undefined,
                connectionId:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.id
                    : cellContent.connection?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connection.unknownDataConnectionId
                      : undefined,
              };
            case "DbtMetricCell":
              return {
                ...cellContent,
                connectionId:
                  cellContent.connection?.__typename === "DataConnection"
                    ? cellContent.connection.id
                    : cellContent.connection?.__typename ===
                        "UnknownDataConnection"
                      ? cellContent.connection.unknownDataConnectionId
                      : undefined,
                metricIds: cellContent.metrics.map((metric) => metric.id),
              };
            case "CellGroupCell":
              // TODO: temp hack to filter out CellGroupCells until fully removed from backend
              return undefined;
            default:
              return cellContent;
          }
        })
        .filter(notEmpty);

      // HACK trick the state into thinking this is defined
      let canvasLayout: CanvasLayoutMP = null as unknown as CanvasLayoutMP;
      let canvasElements: readonly CanvasElementMP[] = [];
      if (canvasLayoutAndElements != null) {
        const { canvasElements: canvasElementsSpread, ...canvasLayoutSpread } =
          canvasLayoutAndElements;
        canvasElements = canvasElementsSpread;
        canvasLayout = canvasLayoutSpread;
      }

      // HACK trick the state into thinking this is defined
      let storyLayout: StoryLayoutMP = null as unknown as StoryLayoutMP;
      let storyElements: readonly StoryElementMP[] = [];
      if (storyLayoutAndElements != null) {
        const { storyElements: storyElementsSpread, ...storyLayoutSpread } =
          storyLayoutAndElements;
        storyElements = storyElementsSpread.filter(
          (s): s is StoryElementMP => s.cell != null,
        );
        storyLayout = storyLayoutSpread;
      }

      let gridLayouts: readonly GridLayoutMP[] = [];
      let gridRows: readonly GridRowMP[] = [];
      if (rawGridLayouts != null) {
        gridRows = rawGridLayouts.flatMap((layout) => layout.gridRows);
        gridLayouts = rawGridLayouts.map(
          ({ gridRows: ________, ...gridLayout }) => gridLayout,
        );
      }

      return {
        canvasElements: canvasElementAdapter.setAll(
          canvasElementAdapter.getInitialState(),
          canvasElements,
        ),
        cells: cellAdapter.setAll(cellAdapter.getInitialState(), cells),
        cellContents: cellContentsAdapter.setAll(
          cellContentsAdapter.getInitialState(),
          cellContents,
        ),
        files: filesAdapter.getInitialState(),
        hexVersion: {
          canvasLayout,
          storyLayout,
          ...hexVersionData,
        },
        magicEvents: magicEventAdapter.getInitialState(),
        secrets: secretsAdapter.getInitialState(),
        hexVersionSecrets: hexVersionSecretsAdapter.getInitialState(),
        syncRepositoryHexVersionLinks:
          syncRepositoryHexVersionLinksAdapter.getInitialState(),
        vcsPackageHexVersionLinks:
          vcsPackageHexVersionLinksAdapter.getInitialState(),
        dataConnectionHexVersionLinks:
          dataConnectionHexVersionLinksAdapter.getInitialState(),
        fileHexVersionLinks: fileHexVersionLinksAdapter.getInitialState(),
        externalFileIntegrationHexVersionLinks:
          externalFileIntegrationHexVersionLinksAdapter.getInitialState(),
        sharedFilters: sharedFiltersAdapter.setAll(
          sharedFiltersAdapter.getInitialState(),
          action.payload.data.sharedFilters,
        ),
        storyElements: storyElementAdapter.setAll(
          storyElementAdapter.getInitialState(),
          storyElements,
        ),
        staticCellIdToCellId: cells.reduce<Record<StaticCellId, CellId>>(
          (acc, curr) => {
            acc[curr.staticId] = curr.id;
            return acc;
          },
          {},
        ),
        cellContentsIdToCellId: cellContents.reduce<Record<string, CellId>>(
          (acc, contents) => {
            acc[cellContentsMPToId(contents)] = contents.cellId;
            return acc;
          },
          {},
        ),
        gridLayouts: gridLayoutAdapter.setAll(
          gridLayoutAdapter.getInitialState(),
          gridLayouts,
        ),
        gridRows: gridRowAdapter.setAll(
          gridRowAdapter.getInitialState(),
          gridRows,
        ),
      };
    },
  },
});

//#region selectors
// We wrap each function that creates selectors in `memoize`. This has a few benefits:
//   - We don't have to construct a whole new object of selectors each call
//   - And more importantly, you get back the same selector instances each time you call it,
//     which means that the cache for reselect selectors will work globally and thus will only
//     have to run the computation one time (instead of once per component)
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

const getCellSelectors = memoize((hexVersionId: HexVersionId) => {
  const baseSelectors = getSelectorsForEntityState(
    cellAdapter,
    (state) => state.hexVersionMP[hexVersionId]?.cells,
  );

  const selectSorted = createDraftSafeSelector(
    baseSelectors.selectAll,
    (allCells) =>
      allCells != null
        ? sortCells(allCells.filter((c) => c.deletedDate == null))
        : [],
  );

  const selectFlattenedSorted = createDraftSafeSelector(
    selectSorted,
    (sortedCells) => flattenSortedCells(sortedCells),
  );

  const createSelectSortedChildCells = (parentCellId: CellId | null) =>
    createDraftSafeSelector(selectFlattenedSorted, (flattenedSorted) =>
      flattenedSorted.filter((c) => c.parentCellId === parentCellId),
    );

  return {
    ...baseSelectors,
    /**
     * Get all cells in the current hex version in sorted order.
     *
     * For more info about how to use this format, see {@link sortCells}
     */
    selectSorted,
    /**
     * Get a flat list of all cells in the current hex version in sorted order.
     *
     * This has some potential ramifications for correctness, so be sure this is
     * what you want and not {@link selectSorted}
     *
     * For more info, see {@link flattenSortedCells}
     */
    selectFlattenedSorted,
    /**
     * Create a memoized selector that gets all direct children of the passed cell
     * in sorted order.
     *
     * Recommended to create via `useMemo` if calling from a react component
     */
    createSelectSortedChildCells,
    selectByStaticId: (state: RootState, staticCellId: StaticCellId) => {
      const cellId =
        state.hexVersionMP[hexVersionId]?.staticCellIdToCellId[staticCellId];
      if (cellId == null) {
        return undefined;
      }
      return baseSelectors.selectById(state, cellId);
    },
    selectByCellContentsId: (state: RootState, cellContentsId: string) => {
      const cellId =
        state.hexVersionMP[hexVersionId]?.cellContentsIdToCellId[
          cellContentsId
        ];
      if (cellId == null) {
        return undefined;
      }
      return baseSelectors.selectById(state, cellId);
    },
  };
});

const getCellContentSelectors = memoize((hexVersionId: HexVersionId) => {
  const baseSelectors = getSelectorsForEntityState<CellContentsMP, CellId>(
    cellContentsAdapter,
    (state) => state.hexVersionMP[hexVersionId]?.cellContents,
  );

  const selectEntitiesByCellContentsId = createDraftSafeSelector(
    baseSelectors.selectEntities,
    (entities) =>
      entities != null
        ? typedObjectFromEntries(
            Object.values(entities)
              .filter(notEmpty)
              .map((contents) => [cellContentsMPToId(contents), contents]),
          )
        : null,
  );

  return {
    selectEntities: baseSelectors.selectEntities,
    selectEntitiesByCellContentsId,
    selectByCellId: baseSelectors.selectById,
    selectByCellContentsId: (state: RootState, cellContentsId: string) => {
      const cellId =
        state.hexVersionMP[hexVersionId]?.cellContentsIdToCellId[
          cellContentsId
        ];
      if (cellId == null) {
        return undefined;
      }
      return baseSelectors.selectById(state, cellId);
    },
    // Because this selector takes in an array of `cellIds`, it's not really super great because
    // often times the caller will pass in a new Array instance or a different instance than last time
    // and so no memoization will happen and a new object will be returned from this.
    // However, the new default `weakMapMemoize` of reselect v5 might make this more useful.
    selectByCellIds: createDraftSafeSelector(
      baseSelectors.selectAll,
      (_state: RootState, cellIds: CellId[]) => cellIds,
      (cellContents, cellIds) => {
        return cellContents
          ?.filter((contents) => cellIds.includes(contents.cellId))
          .filter(notEmpty)
          .reduce<Record<CellId, CellContentsMP>>((acc, curr) => {
            acc[curr.cellId] = curr;
            return acc;
          }, {});
      },
    ),
  };
});

export const hexVersionMPSelectors = {
  getCanvasElementSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      canvasElementAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.canvasElements,
    );

    return {
      ...baseSelectors,
      selectCellIdToCanvasElements: createDraftSafeSelector(
        baseSelectors.selectAll,
        (canvasElements) => groupBy(canvasElements ?? [], (ele) => ele.cell.id),
      ),
    };
  }),

  getCellSelectors,

  getMagicEventSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityState(
      magicEventAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.magicEvents,
    );

    const selectParentEventIdToMagicEvent = createDraftSafeSelector(
      baseSelectors.selectAll,
      (magicEvents) =>
        groupBy(
          (magicEvents ?? []).filter((e) => !!e.parentEventId),
          (e) => e.parentEventId,
        ),
    );

    const selectMagicEventsByParentId = (
      state: RootState,
      parentId: MagicEventId,
    ): MagicEventFragment[] =>
      selectParentEventIdToMagicEvent(state)[parentId] ??
      stableEmptyArray<MagicEventFragment>();

    return {
      ...baseSelectors,
      selectMagicEventsByParentId,
    };
  }),

  getCellContentSelectors,

  /**
   * WARN many of these selectors subscribe to all cell contents and should
   * be used with caution. Consider only ever using in a getter.
   */
  getCellContentAwareSelectors: memoize((hexVersionId: HexVersionId) => {
    const cellSelectors = getCellSelectors(hexVersionId);
    const cellContentsSelectors = getCellContentSelectors(hexVersionId);

    // Memoize the results of combining cells
    // like this so that we get a relatively stable result
    const makeResolvedCell = memoize(
      (cell: CellMP, cellContents: CellContentsMP): ResolvedCellMP => ({
        ...cell,
        cellContents,
      }),
      { maxSize: 500 },
    );

    const selectResolvedSortedFlattenedCells = createDraftSafeSelector(
      cellSelectors.selectFlattenedSorted,
      cellContentsSelectors.selectEntities,
      (cells, cellsContentsRecord) =>
        cells.flatMap((cell) => {
          const contents = cellsContentsRecord?.[cell.id];
          if (contents == null) {
            return [];
          }
          return [makeResolvedCell(cell, contents)];
        }),
    );

    const createSelectResolvedSortedChildCells = (
      parentCellId: CellId | null,
    ) =>
      createDraftSafeSelector(
        selectResolvedSortedFlattenedCells,
        (resolvedFlattenedSorted) =>
          resolvedFlattenedSorted.filter(
            (c) => c.parentCellId === parentCellId,
          ),
      );

    const selectSortedCellsWithoutSQLBlockChildren = createDraftSafeSelector(
      cellSelectors.selectSorted,
      cellContentsSelectors.selectEntities,
      (sortedCells, contents) =>
        filterSortedCellsChildren(sortedCells, (cell) => {
          if (contents != null) {
            const cellContents = contents[cell.id];
            return (
              cellContents?.__typename !== "BlockCell" ||
              !SQLCellBlockConfig.guard(cellContents.blockConfig)
            );
          } else {
            return true;
          }
        }),
    );

    const selectFlattenedSortedCellsWithoutSQLBlockChildren =
      createDraftSafeSelector(
        selectSortedCellsWithoutSQLBlockChildren,
        (sortedCells) => flattenSortedCells(sortedCells),
      );

    const selectSortedCellsWithoutSQLBlockOrComponentChildren =
      createDraftSafeSelector(
        selectSortedCellsWithoutSQLBlockChildren,
        (sortedCells) =>
          filterSortedCellsChildren(
            sortedCells,
            (cell) => cell.cellType !== CellType.COMPONENT_IMPORT,
          ),
      );

    const selectFlattenedSortedCellsWithoutSQLBlockOrComponentChildren =
      createDraftSafeSelector(
        selectSortedCellsWithoutSQLBlockOrComponentChildren,
        (sortedCells) => flattenSortedCells(sortedCells),
      );

    const selectCellIdToLabel = createDraftSafeSelector(
      cellSelectors.selectFlattenedSorted,
      cellContentsSelectors.selectEntities,
      cellContentsSelectors.selectEntitiesByCellContentsId,
      (sortedCells, cellContentsMap, cellContentsMapByContentsId) => {
        const result: Record<CellId, string | undefined> = {};
        let topLevelIdx = 0;
        let componentIdx = 0;
        for (const cell of sortedCells) {
          const cellContents = cellContentsMap?.[cell.id];

          if (cell.cellType === "BLOCK" || cellContents == null) {
            continue;
          } else if (cell.parentBlockCellId != null) {
            const parentContents =
              cellContentsMapByContentsId?.[cell.parentBlockCellId];

            if (
              parentContents == null ||
              parentContents.__typename !== "BlockCell" ||
              parentContents.blockConfig == null
            ) {
              continue;
            } else if (
              // continue if this is not the sql cell of a SQL cell block
              SQLCellBlockConfig.guard(parentContents.blockConfig) &&
              cell.id !== parentContents.blockConfig.sqlCellId
            ) {
              continue;
            }
          }

          let idx: number;
          if (cell.parentComponentImportCellId == null) {
            idx = topLevelIdx;
            componentIdx = 0;
            topLevelIdx++;
          } else {
            idx = componentIdx;
            componentIdx++;
          }

          result[cell.id] = resolveCellLabel({
            cellIndex: idx,
            cell,
            cellContents,
          });
        }

        // For SQL block cells, make their label the label of their primary SQL cell
        for (const cell of sortedCells) {
          const cellContents = cellContentsMap?.[cell.id];
          if (
            cellContents?.__typename === "BlockCell" &&
            cellContents.blockConfig != null &&
            SQLCellBlockConfig.guard(cellContents.blockConfig)
          ) {
            result[cell.id] = result[cellContents.blockConfig.sqlCellId];
          }
        }

        return result;
      },
    );

    const selectLabelByCellId = (
      state: RootState,
      cellId: CellId,
    ): string | undefined => selectCellIdToLabel(state)[cellId];

    const selectLabelByStaticCellId = (
      state: RootState,
      staticCellId: StaticCellId,
    ): string | undefined => {
      const cellId =
        state.hexVersionMP[hexVersionId]?.staticCellIdToCellId[staticCellId];
      if (cellId == null) {
        return undefined;
      }
      return selectLabelByCellId(state, cellId);
    };

    return {
      /**
       * Gets all cells 'resolved' with their cell contents in a single object.
       *
       * In some places we render a cell list, we expect an object of this shape,
       * but in general you should prefer just selecting the relevant cells themselves
       * and letting a child component select the contents instead.
       */
      selectResolvedSortedFlattenedCells,
      /**
       * 'resolved' cell version of {@link createSelectSortedChildCells}.
       *
       * For more info, also see {@link selectResolvedSortedFlattenedCells}
       */
      createSelectResolvedSortedChildCells,
      /**
       * Frequently when dealing with cells it can be convenient to ignore
       * SQL block children since they are not user facing.
       */
      selectSortedCellsWithoutSQLBlockChildren,
      /**
       * Frequently when dealing with cells it can be convenient to ignore
       * SQL block children since they are not user facing.
       */
      selectFlattenedSortedCellsWithoutSQLBlockChildren,
      /**
       * When traversing cell for magic, we only want to deal with visible cells
       * that can be edited by users, so we skip sql block children and component children
       *
       * TODO: consider making omitting specific child types arguments and use weak map memoization
       * need to upgrade reselect first though
       */
      selectSortedCellsWithoutSQLBlockOrComponentChildren,
      /**
       * When traversing cell for magic, we only want to deal with visible cells
       * that can be edited by users, so we skip sql block children and component children
       *
       * TODO: consider making omitting specific child types arguments and use weak map memoization
       * need to upgrade reselect first though
       */
      selectFlattenedSortedCellsWithoutSQLBlockOrComponentChildren,
      selectCellIdToLabel,
      selectLabelByCellId,
      selectLabelByStaticCellId,
    };
  }),

  getFileSelectors: memoize((hexVersionId: HexVersionId) =>
    getSelectorsForEntityState(
      filesAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.files,
    ),
  ),

  getSharedComponentSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseCellContentsSelectors = getSelectorsForEntityState(
      cellContentsAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.cellContents,
    );

    const baseCellSelectors = getSelectorsForEntityState(
      cellAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.cells,
    );

    return {
      selectImportedComponentVersions: createDraftSafeSelector(
        baseCellContentsSelectors.selectAll,
        (cellContents) => {
          return cellContents
            ?.map((contents) =>
              contents.__typename === "ComponentImportCell" &&
              contents.deletedDate == null
                ? contents.componentVersionStub
                : undefined,
            )
            .filter(notEmpty);
        },
      ),
      getSelectChildCellsSelector: memoize(
        (componentImportCellId: string | undefined) =>
          createDraftSafeSelector(baseCellSelectors.selectAll, (cells) => {
            return cells
              ?.filter(
                (cell) =>
                  cell.parentComponentImportCellId === componentImportCellId &&
                  cell.deletedDate == null,
              )
              .filter(notEmpty)
              .reduce<Record<CellId, CellMP>>((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
              }, {});
          }),
      ),
    };
  }),

  getGridRowSelectors: memoize((hexVersionId: HexVersionId) => {
    // need to ignore grid rows in deleted grid layouts
    // since the chain delete might not catch all grid rows due to concurrency
    const layoutSelectors = getSelectorsForEntityStateWithSoftDelete(
      gridLayoutAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.gridLayouts,
    );
    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      gridRowAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.gridRows,
    );

    const layoutAwareSeletors = {
      ...baseSelectors,
      selectAll: createDraftSafeSelector(
        baseSelectors.selectAll,
        layoutSelectors.selectEntitiesWithDeleted,
        (entities, layouts) =>
          entities == null
            ? null
            : entities.filter(
                (e) => layouts?.[e.gridLayoutId]?.deletedDate == null,
              ),
      ),
      selectById: (state: RootState, id: GridRowId) => {
        const entity = baseSelectors.selectById(state, id);
        if (entity == null) {
          return null;
        }
        const layout = baseSelectors.selectByIdWithDeleted(state, id);
        return layout?.deletedDate != null ? null : entity;
      },
      selectEntities: createDraftSafeSelector(
        baseSelectors.selectAll,
        layoutSelectors.selectEntitiesWithDeleted,
        (entities, layouts) =>
          entities == null
            ? null
            : Object.fromEntries(
                Object.entries(entities).filter(
                  ([_, ele]) =>
                    layouts?.[ele.gridLayoutId]?.deletedDate == null,
                ),
              ),
      ),
    };

    return {
      ...layoutAwareSeletors,
      selectGridElementIdToGridElements: createDraftSafeSelector(
        layoutAwareSeletors.selectAll,
        (gridRows) => {
          const gridColumns = (gridRows ?? []).flatMap(
            (row) => row.gridColumns,
          );
          const gridElements = gridColumns.flatMap((col) => col.gridElements);
          return keyBy(gridElements, (ele) => ele.id);
        },
      ),
      selectEntityIdToGridElements: createDraftSafeSelector(
        layoutAwareSeletors.selectAll,
        (gridRows) => {
          const gridColumns = (gridRows ?? []).flatMap(
            (row) => row.gridColumns,
          );
          const gridElements = gridColumns.flatMap((col) => col.gridElements);
          return groupBy(gridElements, (ele) => ele.entityId);
        },
      ),
      selectEntityIdsWithGridElements: createDraftSafeSelector(
        layoutAwareSeletors.selectAll,
        (gridRows) => {
          const gridColumns = (gridRows ?? []).flatMap(
            (row) => row.gridColumns,
          );
          const gridElements = gridColumns.flatMap((col) => col.gridElements);
          const gridElementsMap = groupBy(gridElements, (ele) => ele.entityId);
          return new Set(Object.keys(gridElementsMap) as GridElementEntityId[]);
        },
      ),
    };
  }),

  getHexVersionSelectors: memoize((hexVersionId: HexVersionId) => ({
    select: (state: RootState): HexVersionMP => {
      const hexVersion = state.hexVersionMP[hexVersionId]?.hexVersion;
      assertNonNull(
        hexVersion,
        `Can't select non-existent hex version of id ${hexVersionId}`,
      );
      return hexVersion;
    },
    safeSelect: (state: RootState): HexVersionMP | null =>
      state.hexVersionMP[hexVersionId]?.hexVersion ?? null,
  })),

  getCanvasLayoutSelectors: memoize((hexVersionId: HexVersionId) => ({
    select: (state: RootState): CanvasLayoutMP => {
      const canvasLayout =
        state.hexVersionMP[hexVersionId]?.hexVersion.canvasLayout;
      assertNonNull(
        canvasLayout,
        `Can't select canvas layout for hex version ${hexVersionId}`,
      );
      return canvasLayout;
    },
    safeSelect: (state: RootState): CanvasLayoutMP | null =>
      state.hexVersionMP[hexVersionId]?.hexVersion.canvasLayout ?? null,
  })),

  getGridLayoutSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityStateWithSoftDelete(
      gridLayoutAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.gridLayouts,
    );

    return {
      ...baseSelectors,
      selectPrimary: (state: RootState): GridLayoutMP => {
        const gridLayouts = baseSelectors.selectAll(state);
        const gridLayout = gridLayouts?.[0];
        assertNonNull(
          gridLayout,
          `Can't select grid layout for hex version ${hexVersionId}`,
        );
        return gridLayout;
      },
      safeSelectPrimary: (state: RootState): GridLayoutMP | null => {
        const gridLayouts = baseSelectors.selectAll(state);
        const gridLayout = gridLayouts?.[0];
        return gridLayout ?? null;
      },
    };
  }),

  getSecretSelectors: memoize((hexVersionId: HexVersionId) =>
    getSelectorsForEntityStateWithSoftDelete(
      secretsAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.secrets,
    ),
  ),

  getEditorUserAttributesOverrideSelectors: memoize(
    (hexVersionId: HexVersionId) => ({
      safeSelectByKey: (state: RootState, key: string) =>
        state.hexVersionMP[hexVersionId]?.hexVersion
          .editorUserAttributesOverride?.[key] ?? null,
      selectAll: (state: RootState) =>
        state.hexVersionMP[hexVersionId]?.hexVersion
          .editorUserAttributesOverride ?? {},
      selectUserAttributesOverrideModeActive: createDraftSafeSelector(
        hexVersionMPSelectors.getHexVersionSelectors(hexVersionId).safeSelect,
        getCellContentSelectors(hexVersionId).selectEntities,
        (state: RootState, hexId: HexId) => state.hexMP[hexId]?.hex,
        (hexVersion, cellContents, hex) => {
          assertNonNull(hex, `Can't select non-existent hex`);
          assertNonNull(
            hexVersion,
            `Can't select non-existent hex version of id ${hexVersionId}`,
          );
          const signedEmbeddingEnabled = hex.allowEmbedding;
          const hasHexUserAttributeOverride =
            hexVersion.editorUserAttributesOverride &&
            Object.keys(hexVersion.editorUserAttributesOverride).length > 0;

          // Check the low cost checks first so we can exit early to save some work
          if (signedEmbeddingEnabled || hasHexUserAttributeOverride) {
            return true;
          }

          const hasReferencedHexUserAttribute = Object.values(
            cellContents ?? {},
          ).some((cell) => {
            switch (cell?.__typename) {
              case "SqlCell":
                return (
                  cell.jinjaCellReferencesV3?.referencedParams?.some(
                    ({ param }) => param === HEX_USER_ATTRIBUTES_PARAMETER.name,
                  ) ?? false
                );
              case "CodeCell":
                return (
                  cell.cellReferencesV2?.referencedParams.some(
                    ({ param }) => param === HEX_USER_ATTRIBUTES_PARAMETER.name,
                  ) ?? false
                );
              default:
                return false;
            }
          });
          return hasReferencedHexUserAttribute;
        },
      ),
    }),
  ),

  getHexVersionSecretSelectors: memoize((hexVersionId: HexVersionId) =>
    getSelectorsForEntityStateWithSoftDelete(
      hexVersionSecretsAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.hexVersionSecrets,
    ),
  ),
  getVcsPackageHexVersionLinkSelectors: memoize((hexVersionId: HexVersionId) =>
    getSelectorsForEntityStateWithSoftDelete(
      vcsPackageHexVersionLinksAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.vcsPackageHexVersionLinks,
    ),
  ),
  getSyncRepositoryHexVersionLinkSelectors: memoize(
    (hexVersionId: HexVersionId) =>
      getSelectorsForEntityStateWithSoftDelete(
        syncRepositoryHexVersionLinksAdapter,
        (state) =>
          state.hexVersionMP[hexVersionId]?.syncRepositoryHexVersionLinks,
      ),
  ),
  getDataConnectionHexVersionLinkSelectors: memoize(
    (hexVersionId: HexVersionId) =>
      getSelectorsForEntityStateWithSoftDelete(
        dataConnectionHexVersionLinksAdapter,
        (state) =>
          state.hexVersionMP[hexVersionId]?.dataConnectionHexVersionLinks,
      ),
  ),
  getFileHexVersionLinkSelectors: memoize((hexVersionId: HexVersionId) =>
    getSelectorsForEntityStateWithSoftDelete(
      fileHexVersionLinksAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.fileHexVersionLinks,
    ),
  ),
  getExternalFileIntegrationHexVersionLinkSelectors: memoize(
    (hexVersionId: HexVersionId) =>
      getSelectorsForEntityStateWithSoftDelete(
        externalFileIntegrationHexVersionLinksAdapter,
        (state) =>
          state.hexVersionMP[hexVersionId]
            ?.externalFileIntegrationHexVersionLinks,
      ),
  ),
  getStoryElementSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityState<StoryElementMP, CellId>(
      storyElementAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.storyElements,
    );
    return {
      selectEntities: baseSelectors.selectEntities,
      selectByCellId: baseSelectors.selectById,
      selectAll: baseSelectors.selectAll,
    };
  }),
  getSharedFilterSelectors: memoize((hexVersionId: HexVersionId) => {
    return getSelectorsForEntityStateWithSoftDelete(
      sharedFiltersAdapter,
      (state) => state.hexVersionMP[hexVersionId]?.sharedFilters,
    );
  }),
  getExploreSelectors: memoize((hexVersionId: HexVersionId) => {
    return {
      select: (state: RootState) => {
        return state.hexVersionMP[hexVersionId]?.hexVersion.explore;
      },
    };
  }),
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

//#region full multi-HexVersionMP reducer
type HexVersionMpState = {
  [hexVersionId: string]: HexVersionMPValue | undefined;
};

export const hexVersionMPActions = {
  ...hexVersionMPValueSlice.actions,
} as const;

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(hexVersionMPActions).map((a) => a.type),
);

export const hexVersionMPReducer = createReducer<HexVersionMpState>(
  {},
  (builder) =>
    builder.addMatcher(
      (action): action is HexVersionAction<unknown> =>
        allActionTypes.has(action.type),
      (state, action) => {
        state[action.payload.hexVersionId] = castDraft(
          hexVersionMPValueSlice.reducer(
            state[action.payload.hexVersionId],
            action,
          ),
        );
      },
    ),
);

export function cellContentsMPToId(contents: CellContentsMP): string {
  switch (contents.__typename) {
    case "ChartCell":
      return contents.chartCellId;
    case "CodeCell":
      return contents.codeCellId;
    case "MarkdownCell":
      return contents.markdownCellId;
    case "TextCell":
      return contents.textCellId;
    case "MapCell":
      return contents.mapCellId;
    case "MetricCell":
      return contents.metricCellId;
    case "DbtMetricCell":
      return contents.dbtMetricCellId;
    case "SqlCell":
      return contents.sqlCellId;
    case "PivotCell":
      return contents.pivotCellId;
    case "WritebackCell":
      return contents.writebackCellId;
    case "DisplayTableCell":
      return contents.displayTableCellId;
    case "FilterCell":
      return contents.filterCellId;
    case "ComponentImportCell":
      return contents.componentImportCellId;
    case "VegaChartCell":
      return contents.vegaChartCellId;
    case "Parameter":
      return contents.inputCellId;
    case "BlockCell":
      return contents.blockCellId;
    case "ExploreCell":
      return contents.exploreCellId;
    case "CollapsibleCell":
      return contents.collapsibleCellId;
    default:
      assertNever(contents, (contents as { __typename: string }).__typename);
  }
}

function cellContentsMPToIdFieldName(contents: CellContentsMP): string {
  switch (contents.__typename) {
    case "ChartCell":
      return "chartCellId";
    case "CodeCell":
      return "codeCellId";
    case "MarkdownCell":
      return "markdownCellId";
    case "TextCell":
      return "textCellId";
    case "MapCell":
      return "mapCellId";
    case "MetricCell":
      return "metricCellId";
    case "DbtMetricCell":
      return "dbtMetricCellId";
    case "SqlCell":
      return "sqlCellId";
    case "PivotCell":
      return "pivotCellId";
    case "WritebackCell":
      return "writebackCellId";
    case "DisplayTableCell":
      return "displayTableCellId";
    case "FilterCell":
      return "filterCellId";
    case "ComponentImportCell":
      return "componentImportCellId";
    case "VegaChartCell":
      return "vegaChartCellId";
    case "Parameter":
      return "inputCellId";
    case "BlockCell":
      return "blockCellId";
    case "ExploreCell":
      return "exploreCellId";
    case "CollapsibleCell":
      return "collapsibleCellId";
    default:
      assertNever(contents, (contents as { __typename: string }).__typename);
  }
}
